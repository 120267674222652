<template>
  <v-app>
    <v-row align="center" justify="center">
      <v-card class="login-card">
        <v-row class="form-container">
          <v-col>
            <v-card-text>
              <v-img :src="require('../../assets/logo.svg')" alt="logo-piximind"></v-img>
              <v-form v-model="isValid" @keyup.native.enter="login">
                <TextInput id="email" label="Email" type="email" v-model="email" :rules="emailRules" />
                <TextInput
                  id="password"
                  label="Mot de passe"
                  :type="showIconPassword ? 'text' : 'password'"
                  v-model="password"
                  :rules="passwordRules"
                  @click:append="showIconPassword = !showIconPassword"
                  :append-icon="showIconPassword ? 'mdi-eye' : 'mdi-eye-off'"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <Button action_name="Se connecter" :click_action="login" :disabled="!isValid" :loading="loading" style="width: 100%" @clicked="login" />
              <v-spacer />
            </v-card-actions>
            <v-row>
              <v-col class="forgot-password">
                <router-link to="forgot-password">
                  <a href="#">Mot de passe oublié ?</a>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>
<script>
import { AuthApi } from "@/services/auth.api";
import { Button, TextInput } from "@/components/base";
import jwt_decode from "jwt-decode";

const authService = new AuthApi();
export default {
  components: {
    Button,
    TextInput
  },
  data() {
    return {
      showIconPassword: false,
      loading: false,
      snackbar: false,
      text: "",
      timeout: 2000,
      isValid: true,
      logged: false,
      model: null,
      email: "",
      password: "",
      error: false
    };
  },
  mounted() {
    if (localStorage.token) {
      this.logged = true;
      this.$router.push("/dashboard");
    }
  },
  methods: {
    login() {
      this.loading = true;
      authService
        .login(this.email, this.password)
        .then(
          response => {
            this.LoginSuccessfully(response);
          },
          error => {
            this.loading = false;
            this.text = error.response.data;
            this.snackbar = true;
            // this.$refs.email.focus()
          }
        )
        .catch(() => this.loginFailed());
    },
    loginFailed() {
      this.text = "Login failed!";
      this.snackbar = true;
      delete localStorage.token;
    },

    LoginSuccessfully(response) {
      const decoded = jwt_decode(response.data.access_token);
      const role = decoded.scopes[0];
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("role", role);
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      this.$store.commit("authUser", {
        access_token: response.data.access_token,
        user: response.data.user,
        role: role
      });

      if (role == "admin") {
        this.$router.push("/dashboard/timesheet/list");
      } else {
        this.$router.push("/dashboard/timesheet");
      }
    }
  }
};
</script>
<style scoped>
.v-card__actions {
  padding-right: 16px;
  padding-left: 16px;
}
.login-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0 !important;
}

.forgot-password {
  text-align: center;
}

.forgot-password a {
  text-decoration: none;
  color: #11887e;
  font-family: "Segoe UI", serif;
  font-size: 16px;
}

.form-container {
  padding: 30px;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background: #86bc26 !important;
}

label {
  color: #707070;
  font-family: "Lato", serif;
}
</style>
