import axios from "axios";

const API_URL = process.env.VUE_APP_ROOT_API;

export class AuthApi {
  forgotPassword(email) {
    return axios.post(API_URL + "/reset-password-request", {
      email: email
    });
  }
  resetPassword(email, password, passwordToken) {
    return axios.post(API_URL + "/change-password", {
      email: email,
      password: password,
      passwordToken: passwordToken
    });
  }
  login(email, password) {
    return axios.post(API_URL + "/login", {
      username: email,
      password: password
    });
  }
}
